import React, { useMemo } from 'react'
import classNames from 'classnames/bind'
import { InView } from 'react-intersection-observer'

import { ChronologyTypes } from './ChronologyTypes'

import * as s from './Chronology.module.scss'
import { Title, Subheading, Paragraph } from 'components/ui/Text'
const cn = classNames.bind(s)

const Chronology = ({
  slice: {
    primary: { year, event, description },
  },
}: ChronologyTypes) => {
  const wrappedYear = useMemo(() => year.toString().split(''), [year])

  return (
    <InView triggerOnce threshold={0.5}>
      {({ inView, ref }) => (
        <div className={cn('chronology')} ref={ref}>
          <div className={cn('content')}>
            <div className={cn('year')}>
              <Title className={cn('title', { inView })} aria-label={year} as='h3'>
                {wrappedYear.map((y: string, i: number) => {
                  const correctLigature = i === 3 && y === '1' ? { 'data-ligature-1': true } : {} // 1 ligature correct
                  return (
                    <span key={i} className={cn('char')} {...correctLigature} data-content={y} aria-hidden>
                      <span>{y}</span>
                    </span>
                  )
                })}
              </Title>
            </div>
            <div className={cn('text')}>
              <div ref={ref} className={cn('textInner', { textInView: inView })}>
                <div className={cn('event')}>
                  <Subheading as='h4'>{event.raw[0].text}</Subheading>
                </div>
                <div className={cn('description')}>
                  <Paragraph>{description.raw[0].text}</Paragraph>
                </div>
              </div>
            </div>
          </div>
          <InView triggerOnce>{({ inView, ref }) => <div ref={ref} className={cn('line', { inView })} />}</InView>
        </div>
      )}
    </InView>
  )
}

export default Chronology
