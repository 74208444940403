import React, { useEffect, useRef, memo, useCallback } from 'react'
import { useWindowSize } from '@react-hook/window-size'
import classNames from 'classnames/bind'
import { useViewportScroll } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import { useStore } from 'store/useStore'

import AspectRatio from 'components/core/AspectRatio'

import { LazyBackgroundImageTypes } from './LazyBackgroundImageTypes'

import * as s from './LazyBackgroundImage.module.scss'
const cn = classNames.bind(s)

const LazyBackgroundImage = ({ image, onLoaded }: LazyBackgroundImageTypes) => {
  const [width, height] = useWindowSize()
  const { scrollY } = useViewportScroll()

  const supportsWebp = useStore(s => s.supportsWebp)
  const scrollbar = useStore(s => s.scrollbar)

  const bgRef = useRef<HTMLDivElement>(null)
  const top = useRef<HTMLDivElement>(null)
  const ref = useRef<HTMLDivElement>(null)
  const bottom = useRef<HTMLDivElement>(null)
  const rect = useRef<ClientRect>()

  const [inViewRef, inView] = useInView({
    threshold: 0,
  })

  const setRefs = useCallback(
    node => {
      //@ts-ignore
      ref.current = node
      inViewRef(node)
    },
    [inViewRef],
  )

  useEffect(() => {
    ref.current?.classList.toggle(cn('filter'), inView)
  }, [inView])

  const loadImage = useCallback(() => {
    const img = new Image()
    img.onload = () => {
      onLoaded && onLoaded()
      // ref.current?.classList.add(cn('isLoaded'))
    }
    const src = image.localFile.childImageSharp.fluid[supportsWebp ? 'srcWebp' : 'src']
    setTimeout(() => {
      img.src = src
      if (bgRef.current) bgRef.current.style.backgroundImage = `url(${src})`
      if (top.current) top.current.style.backgroundImage = `url(${src})`
      if (bottom.current) bottom.current.style.backgroundImage = `url(${src})`
    })
  }, [])

  useEffect(() => {
    if (!ref.current) return null
    rect.current = ref.current.getBoundingClientRect()
  }, [ref, width, height])

  useEffect(() => {
    if (!scrollbar || !scrollbar.state?.initialised) {
      if (rect.current.y <= height) {
        loadImage()
        return
      }

      const onNativeScroll = scrollY.onChange(y => {
        if (y > rect.current.y - 2 * height) {
          loadImage()
          onNativeScroll()
        }
        return () => onNativeScroll
      })
    } else {
      if (rect.current.y < height) {
        loadImage()
        return
      }

      const onScrollbarScroll = y => {
        if (y.target > rect.current.y - 2 * height) {
          loadImage()
          scrollbar.off('scroll', onScrollbarScroll)
        }
      }

      scrollbar.on('scroll', onScrollbarScroll)
      return () => scrollbar.off('scroll', onScrollbarScroll)
    }
  }, [scrollbar, scrollY])

  return (
    <div ref={setRefs} className={cn('bg')}>
      <AspectRatio aspectRatio={image.localFile.childImageSharp.fluid.aspectRatio}>
        <div ref={top} className={cn('bgImage', 'aux', 'top')} />
        <div ref={bgRef} className={cn('bgImage')} />
        <div ref={bottom} className={cn('bgImage', 'aux', 'bottom')} />
      </AspectRatio>
    </div>
  )
}

export default memo(LazyBackgroundImage)
