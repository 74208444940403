import React, { memo, useEffect, useCallback, useRef } from 'react'
import classNames from 'classnames/bind'
import { useWindowSize } from '@react-hook/window-size'
import { useInView } from 'react-intersection-observer'
import { useViewportScroll } from 'framer-motion'

import { useStore } from 'store/useStore'

import { ParallaxTypes } from './ParallaxTypes'

import LoFi from 'components/ui/LoFi'

import * as s from './Parallax.module.scss'
const cn = classNames.bind(s)

const MOBILE_SPEED = 0.27

const Parallax = ({ speed = 1, zoomOut = false, lofi = false, children }: ParallaxTypes) => {
  const [width, height] = useWindowSize({ wait: 500 })
  const rect = useRef<ClientRect>()
  const { scrollY } = useViewportScroll()

  const scrollbar = useStore(s => s.scrollbar)
  const isTouch = useStore(s => s.isTouch)
  const wrapper = useRef<HTMLDivElement>(null)
  const trigger = useRef<HTMLDivElement>(null)
  const parallax = useRef<HTMLDivElement>(null)
  const dY = useRef(0)
  const parallaxHeight = useRef(0)

  const [inViewRef, inView] = useInView({
    threshold: 0,
    triggerOnce: false,
  })

  const setRefs = useCallback(
    node => {
      //@ts-ignore
      trigger.current = node
      inViewRef(node)
    },
    [inViewRef],
  )

  useEffect(() => {
    if (!trigger.current || !parallax.current) return
    rect.current = trigger.current.getBoundingClientRect()
    parallaxHeight.current = parallax.current.clientHeight
    if (!scrollbar || !scrollbar.state) return
    dY.current = rect.current.y + scrollbar.state.current
  }, [scrollbar, width, height])

  const renderStyles = useCallback((scale, y) => {
    // if (!parallax.current) return
    // parallax.current.style.transform = `matrix3d(${scale}, 0, 0, 0, 0, ${scale}, 0, 0, 0, 0, 1, 0, 0, ${y}, 0, 1)`
    // parallax.current.style.transform = `matrix(${scale}, 0, 0, ${scale}, 0, ${y})`
    parallax.current.style.transform = `translate3d(0, ${y}px, 0) scale(${scale})`
    // parallax.current.style.transform = `translateY(${y}px) scale(${scale}) translateZ(0px)`
  }, [])

  // Smooth scroll version
  const onScrollbarScroll = useCallback(
    y => {
      if (!parallax.current || !trigger.current || !rect.current) return
      const delta = Math.max(Math.min((y.current - dY.current + height) / (rect.current.height + height), 1), 0)
      const yTranslate = (delta - 0.5) * speed * parallaxHeight.current
      let scale = 1

      if (zoomOut && zoomOut > 0) {
        const dScale = Math.max(1 - delta, 0)
        scale = 1 + dScale * speed * zoomOut
      }

      renderStyles(scale, yTranslate)
    },
    [scrollbar, height, zoomOut],
  )

  const startScrollbarParallax = useCallback(() => {
    scrollbar?.on('tick', onScrollbarScroll)
  }, [scrollbar])

  const stopScrollbarParallax = useCallback(() => {
    scrollbar?.off('tick', onScrollbarScroll)
  }, [scrollbar])

  // Native scroll version
  const onNativeScroll = useCallback(
    y => {
      if (!parallax.current || !trigger.current || !rect.current) return
      const delta = Math.max(Math.min((y + height - rect.current.y) / (rect.current.height + height), 1), 0)
      const yTranslate = (delta - 0.5) * speed * MOBILE_SPEED * parallaxHeight.current
      let scale = 1

      if (zoomOut && zoomOut > 0) {
        const dScale = Math.max(1 - delta, 0)
        scale = 1 + dScale * speed * MOBILE_SPEED * zoomOut
      }

      renderStyles(scale, yTranslate)
    },
    [scrollbar, width, height],
  )

  useEffect(() => {
    if (scrollbar && scrollbar.state?.initialised) {
      inView ? startScrollbarParallax() : stopScrollbarParallax()
    } else if (isTouch) {
      onNativeScroll(0)
      const onYChange = scrollY.onChange(y => {
        onNativeScroll(y)
        if (!inView) onYChange()
      })
      return onYChange
    }
  }, [inView, scrollbar, scrollY, isTouch])

  return (
    <div className={cn('wrapper')} ref={wrapper}>
      <div className={cn('trigger')} ref={setRefs} />
      <div className={cn('inner')}>
        <div className={cn('parallax')} ref={parallax}>
          {children}
        </div>
      </div>
      {lofi && <LoFi isActive={inView} absolute intensity={0.8} />}
    </div>
  )
}

export default memo(Parallax)
