import React from 'react'
import classNames from 'classnames/bind'

import { AspectRatioTypes } from './AspectRatioTypes'

import * as s from './AspectRatio.module.scss'

const cn = classNames.bind(s)

const AspectRatio = ({ aspectRatio, children }: AspectRatioTypes) => (
  <div className={cn('ratioWrapper')} style={{ '--r': aspectRatio } as React.CSSProperties}>
    <div className={cn('ratioContent')}>{children}</div>
  </div>
)

export default AspectRatio
