// extracted by mini-css-extract-plugin
export var colorBgGrey = "#f7f7f7";
export var colorBgWhite = "#fff";
export var colorBgBlue = "#406080";
export var colorSkyBlue = "#406080";
export var colorDarkBlue = "#203040";
export var colorIce = "#dae0e6";
export var colorGranite = "#b1bcc4";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var line = "Chronology-module--line--H5JjP";
export var chronology = "Chronology-module--chronology--qfDPV";
export var scaleIn = "Chronology-module--scaleIn--2al1a";
export var content = "Chronology-module--content--2heLS";
export var year = "Chronology-module--year--2xozF";
export var title = "Chronology-module--title--1Ec56";
export var char = "Chronology-module--char--1V1vk";
export var inView = "Chronology-module--inView--1FScN";
export var text = "Chronology-module--text--2xQy7";
export var textInner = "Chronology-module--textInner--195SI";
export var textInView = "Chronology-module--textInView--3B-5S";
export var event = "Chronology-module--event--2l34R";