import React from 'react'
import classNames from 'classnames/bind'

import SplitSection from './SplitSection'
import { SplitSectionsTypes } from './SplitSectionsTypes'

import * as s from './SplitSections.module.scss'

const cn = classNames.bind(s)

const SplitSections = ({
  slice: {
    primary: { background_color, add_bottom_padding },
    items,
  },
}: SplitSectionsTypes) => {
  return (
    <div className={cn('splitSections', `theme${background_color}`, { bottomPadding: add_bottom_padding })}>
      {items.map((item: {}, index: number) => (
        <SplitSection data={item} key={index} />
      ))}
    </div>
  )
}

export default SplitSections
