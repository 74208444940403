// extracted by mini-css-extract-plugin
export var colorBgGrey = "#f7f7f7";
export var colorBgWhite = "#fff";
export var colorBgBlue = "#406080";
export var colorSkyBlue = "#406080";
export var colorDarkBlue = "#203040";
export var colorIce = "#dae0e6";
export var colorGranite = "#b1bcc4";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var wrapper = "Parallax-module--wrapper--x0-V5";
export var trigger = "Parallax-module--trigger--2m7YU";
export var inner = "Parallax-module--inner--T-QCc";
export var parallax = "Parallax-module--parallax--l0NGc";