// extracted by mini-css-extract-plugin
export var colorBgGrey = "#f7f7f7";
export var colorBgWhite = "#fff";
export var colorBgBlue = "#406080";
export var colorSkyBlue = "#406080";
export var colorDarkBlue = "#203040";
export var colorIce = "#dae0e6";
export var colorGranite = "#b1bcc4";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var splitSections = "SplitSections-module--splitSections--140ji";
export var themeGrey = "SplitSections-module--themeGrey--2ft0X";
export var themeWhite = "SplitSections-module--themeWhite--1ZKgS";
export var bottomPadding = "SplitSections-module--bottomPadding--2eDSo";
export var section = "SplitSections-module--section--2El0r";
export var mobileImageTop = "SplitSections-module--mobileImageTop--SzeOg";
export var desktopImageLeft = "SplitSections-module--desktopImageLeft--2ZenI";
export var textBottom = "SplitSections-module--textBottom--2gmoi";
export var text = "SplitSections-module--text--3kEWP";
export var textTop = "SplitSections-module--textTop--3V1nY";
export var shouldZoomOut = "SplitSections-module--shouldZoomOut--ElqSl";
export var inView = "SplitSections-module--inView--1PmYs";
export var backgroundImage = "SplitSections-module--backgroundImage--1yfjm";
export var cover = "SplitSections-module--cover--1ZyR9";
export var placeholder = "SplitSections-module--placeholder--A3HLU";
export var isLoaded = "SplitSections-module--isLoaded--23G2Q";
export var lofiWrapper = "SplitSections-module--lofiWrapper--eazIZ";
export var content = "SplitSections-module--content--2R4GM";
export var contentImage = "SplitSections-module--contentImage--N_WJk";
export var captions = "SplitSections-module--captions--2rvSy";
export var outer = "SplitSections-module--outer--Fl77D";
export var inner = "SplitSections-module--inner--18EXh";
export var wrapper = "SplitSections-module--wrapper--kL7gj";
export var title = "SplitSections-module--title--rHmhr";
export var captionsMobile = "SplitSections-module--captionsMobile--2-hcx";
export var captionsDesktop = "SplitSections-module--captionsDesktop--3aBzr";
export var paragraphs = "SplitSections-module--paragraphs--OvVAf";