import React, { memo } from 'react'
import classNames from 'classnames/bind'
import { useInView } from 'react-intersection-observer'

import { Caption } from 'components/ui/Text'

import { CaptionsTypes } from './CaptionsTypes'

import * as s from './Captions.module.scss'

const cn = classNames.bind(s)

const Captions = ({ captions }: CaptionsTypes) => {
  const { ref, inView } = useInView({
    threshold: .8,
    triggerOnce: true,
  })

  return (
    <ul className={cn('captions', { inView })} ref={ref}>
      {captions.map((paragraph: { text: string }, i: number) => (
        <li key={`captions-${i}`} className={cn('captionWrapper')}>
          <Caption className={cn('caption')}>{paragraph.text}</Caption>
        </li>
      ))}
    </ul>
  )
}

export default memo(Captions)
