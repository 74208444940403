// extracted by mini-css-extract-plugin
export var colorBgGrey = "#f7f7f7";
export var colorBgWhite = "#fff";
export var colorBgBlue = "#406080";
export var colorSkyBlue = "#406080";
export var colorDarkBlue = "#203040";
export var colorIce = "#dae0e6";
export var colorGranite = "#b1bcc4";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var narrowImage = "NarrowImage-module--narrowImage--3Iv-C";
export var theme = "NarrowImage-module--theme--2BU0Z";
export var themeGrey = "NarrowImage-module--themeGrey--3xF22";
export var themeWhite = "NarrowImage-module--themeWhite--30NKL";