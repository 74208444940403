import React, { useEffect, memo, useRef, useCallback, useMemo } from 'react'
import { useWindowSize } from '@react-hook/window-size'
import classNames from 'classnames/bind'
import { useViewportScroll } from 'framer-motion'

import { useStore } from 'store/useStore'

import AspectRatio from 'components/core/AspectRatio'

import { LazyImageTypes } from './LazyImageTypes'

import * as s from './LazyImage.module.scss'
const cn = classNames.bind(s)

const base64 =
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI5OTk5OXB4IiBoZWlnaHQ9Ijk5OTk5cHgiIHZpZXdCb3g9IjAgMCA5OTk5OSA5OTk5OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48ZyBzdHJva2U9Im5vbmUiIGZpbGw9Im5vbmUiIGZpbGwtb3BhY2l0eT0iMCI+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9Ijk5OTk5IiBoZWlnaHQ9Ijk5OTk5Ij48L3JlY3Q+IDwvZz4gPC9zdmc+'

const LazyImage = ({ image, onLoaded }: LazyImageTypes) => {
  const [width, height] = useWindowSize()
  const { scrollY } = useViewportScroll()

  const supportsWebp = useStore(s => s.supportsWebp)
  const scrollbar = useStore(s => s.scrollbar)

  const src = image.localFile.childImageSharp
  const ref = useRef<HTMLDivElement>(null)
  const rect = useRef<ClientRect>()

  const loadImage = useCallback(() => {
    const img = new Image()
    img.onload = () => {
      ref.current?.classList.add(cn('isLoaded'))
      onLoaded && onLoaded()
      ref.current
        ?.querySelector(`[type="image/${supportsWebp ? 'webp' : 'jpeg'}"]`)
        ?.setAttribute('srcset', src.fluid[supportsWebp ? 'srcSetWebp' : 'srcSet'])
      ref.current?.classList.add(cn('isLoaded'))
    }
    setTimeout(() => {
      img.src = src.fluid[supportsWebp ? 'srcWebp' : 'src']
    })
  }, [src])

  useEffect(() => {
    if (!ref.current) return null
    rect.current = ref.current.getBoundingClientRect()
  }, [ref, width, height])

  useEffect(() => {
    if (!scrollbar || !scrollbar.state?.initialised) {
      const onNativeScroll = scrollY.onChange(y => {
        if (y > rect.current.y - 2 * height) {
          loadImage()
          onNativeScroll()
        }
        return () => onNativeScroll
      })
    } else {
      const onScrollbarScroll = y => {
        if (y.target > rect.current.y - 2 * height) {
          loadImage()
          scrollbar.off('scroll', onScrollbarScroll)
        }
      }
      
      scrollbar.on('scroll', onScrollbarScroll)
      return () => scrollbar.off('scroll', onScrollbarScroll)
    }
  }, [scrollbar, scrollY])

  return (
    <AspectRatio aspectRatio={image.localFile.childImageSharp.fluid.aspectRatio}>
      <picture className={cn('picture')} ref={ref}>
        <source type='image/webp' />
        <source type='image/jpeg' />
        <img src={base64} alt={image.alt} height={src.original.height} width={src.original.width} />
      </picture>
    </AspectRatio>
  )
}

export default memo(LazyImage)
