import React from 'react'
import classNames from 'classnames/bind'
import { useInView } from 'react-intersection-observer'

import LazyImage from 'components/ui/LazyImage'

import { ContentImageTypes } from './SplitSectionsTypes'

import * as s from './SplitSections.module.scss'

const cn = classNames.bind(s)

const ContentImage = ({ image }: ContentImageTypes) => {
  const { ref, inView } = useInView({
    threshold: 0.25,
    triggerOnce: true,
  })

  return (
    <div className={cn('contentImage', { inView })} ref={ref}>
      <div className={cn('outer')}>
        <div className={cn('inner')}>
          <div className={cn('wrapper')}>
            <LazyImage image={image} offsetMargin='150%' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContentImage
