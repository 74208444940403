import React from 'react'
import classNames from 'classnames/bind'

import Parallax from 'components/ui/Parallax'
import { Paragraph } from 'components/ui/Text'
import Captions from 'components/ui/Captions'

import { FullWidthImageTypes } from './FullWidthImageTypes'

import * as s from './FullWidthImage.module.scss'
import LazyImage from 'components/ui/LazyImage'

const cn = classNames.bind(s)

const FullWidthImage = ({
  slice: {
    primary: { image, background_color, captions, captions_mobile, description },
  },
  index,
  total,
}: FullWidthImageTypes) => (
  <div className={cn('fullWidthImage', `theme${background_color}`)}>
    <Parallax speed={0.75} zoomOut={index === total - 1 ? 0.33 : 0.2}>
      <LazyImage image={image} />
    </Parallax>
    {(!!captions.raw.length || !!description.raw.length) && (
      <div className={cn('content')}>
        {!!captions.raw.length && (
          <div className={cn('captions', 'desktop')}>
            <Captions captions={captions.raw} />
          </div>
        )}
        {!!captions_mobile.raw.length && (
          <div className={cn('captions', 'mobile')}>
            <Captions captions={captions_mobile.raw} />
          </div>
        )}
        {!!description.raw.length && (
          <div className={cn('description')}>
            {description.raw.map((paragraph: { text: string }, i: number) => (
              <Paragraph key={`description-${i}`}>{paragraph.text}</Paragraph>
            ))}
          </div>
        )}
      </div>
    )}
  </div>
)

export default FullWidthImage
