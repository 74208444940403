// extracted by mini-css-extract-plugin
export var colorBgGrey = "#f7f7f7";
export var colorBgWhite = "#fff";
export var colorBgBlue = "#406080";
export var colorSkyBlue = "#406080";
export var colorDarkBlue = "#203040";
export var colorIce = "#dae0e6";
export var colorGranite = "#b1bcc4";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var fullWidthImage = "FullWidthImage-module--fullWidthImage--3mFFK";
export var themeGrey = "FullWidthImage-module--themeGrey--2NA1g";
export var themeWhite = "FullWidthImage-module--themeWhite--2Au00";
export var content = "FullWidthImage-module--content--3hbzn";
export var description = "FullWidthImage-module--description--2Qw17";
export var captions = "FullWidthImage-module--captions--VTUdX";
export var mobile = "FullWidthImage-module--mobile--3fY4I";
export var desktop = "FullWidthImage-module--desktop--jwSPe";