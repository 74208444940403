// extracted by mini-css-extract-plugin
export var colorBgGrey = "#f7f7f7";
export var colorBgWhite = "#fff";
export var colorBgBlue = "#406080";
export var colorSkyBlue = "#406080";
export var colorDarkBlue = "#203040";
export var colorIce = "#dae0e6";
export var colorGranite = "#b1bcc4";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var captionWrapper = "Captions-module--captionWrapper--1aNdO";
export var caption = "Captions-module--caption--1mdZ3";
export var inView = "Captions-module--inView--2xbl3";