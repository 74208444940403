import React from 'react'
import classNames from 'classnames/bind'
import { InView } from 'react-intersection-observer'
import { RichText } from 'prismic-reactjs'

import { Paragraph, Caption } from 'components/ui/Text'
import AnimatedTitle from 'components/ui/AnimatedTitle'
import Captions from 'components/ui/Captions'

import BackgroundImage from './BackgroundImage'
import ContentImage from './ContentImage'

import { SectionTypes } from './SplitSectionsTypes'

import * as s from './SplitSections.module.scss'

const cn = classNames.bind(s)

const _ = (str: string) => (str ? str.replace(/\s/g, '') : '')

const SplitSection = ({
  data: {
    desktop_layout,
    mobile_layout,
    text_alignment,
    background_image,
    title,
    text,
    captions_desktop,
    captions_mobile,
    content_image,
    background_color = '',
    parallax_speed
  },
}: SectionTypes) => (
  <div
    className={cn(
      'section',
      `desktop${_(desktop_layout)}`,
      `mobile${_(mobile_layout)}`,
      `text${_(text_alignment)}`,
      `theme${_(background_color)}`,
    )}
  >
    {/* Content half */}
    <div className={cn('content')}>
      {/* Title */}
      {!!title.raw.length && (
        <div className={cn('title')}>
          <InView triggerOnce threshold={0.5}>
            {({ inView, ref }) => (
              <div ref={ref}>
                <AnimatedTitle text={title.raw[0].text} skipWhiteSpace charByChar animateIn={inView} />
              </div>
            )}
          </InView>
        </div>
      )}
      {/* Content Image */}
      {content_image.localFile && <ContentImage image={content_image} />}
      {/* Text, paragraphs */}
      {!!text.raw.length && (
        <div className={cn('text')}>
          {/* {text.raw.map((paragraph: { text: string }, i: number) => (
            <Paragraph key={`text-${i}`} as='div'>
              {paragraph.text}
            </Paragraph>
          ))} */}
          <Paragraph className={cn('paragraphs')} as='div'>
            {RichText.render(text.raw)}
          </Paragraph>
        </div>
      )}
      {/* Captions, desktop and mobile */}
      {(!!captions_desktop.raw.length || !!captions_mobile.raw.length) && (
        <div className={cn('captions')}>
          {!!captions_desktop.raw.length && (
            <div className={cn('captionsDesktop')}>
              <Captions captions={captions_desktop.raw} />
            </div>
          )}
          {!!captions_mobile.raw.length && (
            <div className={cn('captionsMobile')}>
              <Captions captions={captions_mobile.raw} />
            </div>
          )}
        </div>
      )}
    </div>
    {/* Background half */}
    <BackgroundImage image={background_image} shouldZoomOut={!!title.raw.length} pSpeed={parallax_speed} />
  </div>
)

export default SplitSection
