import React, { useMemo } from 'react'

import SectionTitle from 'components/ui/Slices/SectionTitle'
import NarrowImage from 'components/ui/Slices/NarrowImage'
import SplitSections from 'components/ui/Slices/SplitSections'
import FullWidthImage from 'components/ui/Slices/FullWidthImage'
import Chronology from 'components/ui/Slices/Chronology'

import { SliceZoneTypes } from './SliceZoneTypes'

const SliceComponents = {
  section_title: SectionTitle,
  narrow_image: NarrowImage,
  split_sections: SplitSections,
  full_width_image: FullWidthImage,
  chronology: Chronology,
}

const getSlices = (slices: [], page: 'homepage' | 'page') =>
  slices
    // .filter(s => s.slice_type !== 'section_title')
    // .filter(s => s.slice_type !== 'split_sections')
    .map((slice: { id: string; slice_type: string; primary: { isMainTitle?: boolean } }, index) => {
      if (page === 'page' && slice.slice_type === 'section_title' && index === 0) {
        slice.primary.isMainTitle = true
      }
      // @ts-ignore
      const SliceComponent = SliceComponents[slice.slice_type]
      if (!SliceComponent) return null
      return (
        <div key={`slice-${slice.id}`} data-smooth-item data-type={slice.slice_type}>
          <SliceComponent slice={slice} index={index} total={slices.length} />
        </div>
      )
    })

const SliceZone = ({ slices, page }: SliceZoneTypes) => {
  const sliceZoneContent = useMemo(() => getSlices(slices, page), [slices])
  return <div className='content'>{sliceZoneContent}</div>
}

export default SliceZone
