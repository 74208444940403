import React from 'react'
import classNames from 'classnames/bind'

import Parallax from 'components/ui/Parallax'
import LazyImage from 'components/ui/LazyImage'

import { NarrowImageTypes } from './NarrowImageTypes'

import * as s from './NarrowImage.module.scss'

const cn = classNames.bind(s)

const NarrowImage = ({
  slice: {
    primary: { image, background_color },
  },
}: NarrowImageTypes) => (
  <div className={cn('narrowImage', `theme${background_color}`)}>
    <Parallax speed={.33}>
      <div className={cn('wrapper')}>
        <LazyImage image={image} />
      </div>
    </Parallax>
  </div>
)

export default NarrowImage
