// extracted by mini-css-extract-plugin
export var colorBgGrey = "#f7f7f7";
export var colorBgWhite = "#fff";
export var colorBgBlue = "#406080";
export var colorSkyBlue = "#406080";
export var colorDarkBlue = "#203040";
export var colorIce = "#dae0e6";
export var colorGranite = "#b1bcc4";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var wrapper = "LoFi-module--wrapper--1U3VX";
export var fadeIn = "LoFi-module--fadeIn--26HxE";
export var flicker = "LoFi-module--flicker--2vjMP";
export var absolute = "LoFi-module--absolute--30tP4";
export var isActive = "LoFi-module--isActive--23XrE";