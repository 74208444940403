import React, { useState, useMemo } from 'react'
import classNames from 'classnames/bind'
import { InView } from 'react-intersection-observer'

import LazyBackgroundImage from 'components/ui/LazyBackgroundImage'
import Parallax from 'components/ui/Parallax'

import { BackgroundImageTypes, CoverImageTypes } from './SplitSectionsTypes'

import * as s from './SplitSections.module.scss'

const cn = classNames.bind(s)

const CoveredImage = ({ placeholder, image, shouldZoomOut }: CoverImageTypes) => {
  const [isLoaded, setIsLoaded] = useState(false)

  return (
    <>
      <InView triggerOnce>
        {({ inView, ref }) => (
          <div className={cn('bgImageWrapper')} ref={ref}>
            <div className={cn({ shouldZoomOut, inView })}>
              <LazyBackgroundImage image={image} onLoaded={() => setIsLoaded(true)} />
            </div>
          </div>
        )}
      </InView>
      <InView threshold={0.12} triggerOnce>
        {({ inView, ref }) => (
          <div className={cn('cover', { inView, isLoaded })} ref={ref}>
            <div
              className={cn('placeholder')}
              style={{ backgroundImage: `url(${placeholder})` } as React.CSSProperties}
            />
          </div>
        )}
      </InView>
    </>
  )
}

const BackgroundImage = ({ image, shouldZoomOut = false, pSpeed }: BackgroundImageTypes) => {
  const parallaxSpeed = useMemo(() => {
    const ratio = image.localFile.childImageSharp.fluid.aspectRatio
    if (pSpeed) {
      return ratio * pSpeed
    }
    const speed = ratio < 1 ? (ratio < 0.7 ? 0.5 : 1.5) : 0.75
    return ratio * speed
  }, [image])

  return (
    <div className={cn('backgroundImage')}>
      <Parallax speed={parallaxSpeed} lofi>
        <CoveredImage
          image={image}
          placeholder={image.localFile.childImageSharp.fluid.base64}
          shouldZoomOut={shouldZoomOut}
        />
      </Parallax>
    </div>
  )
}

export default BackgroundImage
