import React, { memo } from 'react'
import classNames from 'classnames/bind'

import { LoFiTypes } from './LoFiTypes'

import * as s from './LoFi.module.scss'

const cn = classNames.bind(s)

const LoFi = ({ isActive = false, absolute = false, intensity = 1, fadeIn = false, children }: LoFiTypes) => (
  <div
    className={cn('wrapper', { isActive, absolute, fadeIn })}
    style={{ '--intensity': intensity } as React.CSSProperties}
  >
    {children}
  </div>
)

export default memo(LoFi)
