// extracted by mini-css-extract-plugin
export var colorBgGrey = "#f7f7f7";
export var colorBgWhite = "#fff";
export var colorBgBlue = "#406080";
export var colorSkyBlue = "#406080";
export var colorDarkBlue = "#203040";
export var colorIce = "#dae0e6";
export var colorGranite = "#b1bcc4";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var bgImage = "LazyBackgroundImage-module--bgImage--2TCUs";
export var bg = "LazyBackgroundImage-module--bg--1P2ZY";
export var top = "LazyBackgroundImage-module--top--BbHPI";
export var bottom = "LazyBackgroundImage-module--bottom--1Fx9u";
export var aux = "LazyBackgroundImage-module--aux--2lsKn";
export var filter = "LazyBackgroundImage-module--filter--3jbz8";